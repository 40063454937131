<!--
 * @Author: wang peng
 * @Description:  新增/编辑
-->
<template>
  <ykc-form class="el-form-wrap">
    <div>
      <ykc-form :model="ruleForm" :rules="rules" ref="ruleForm" class="el-form-wrap">
        <ykc-form-item label="电桩型号" prop="modelName">
          <ykc-input
            placeholder="请输入电桩型号"
            maxlength="30"
            v-model="ruleForm.modelName"></ykc-input>
        </ykc-form-item>

        <ykc-form-item label="电桩品牌" prop="isName">
          <ykc-radio
            :data="[
              { id: 1, name: '已有品牌' },
              { id: 2, name: '新增品牌' },
            ]"
            v-model="ruleForm.isName"></ykc-radio>
        </ykc-form-item>

        <ykc-form-item label="选择品牌" prop="name" v-if="ruleForm.isName === 1">
          <ykc-dropdown :data="brandList" v-model="ruleForm.name"></ykc-dropdown>
        </ykc-form-item>

        <ykc-form-item label="新增品牌" prop="inputName" v-if="ruleForm.isName === 2">
          <ykc-input
            placeholder="请输入新增品牌"
            maxlength="30"
            v-model="ruleForm.inputName"></ykc-input>
        </ykc-form-item>

        <ykc-form-item label="电桩类型" prop="pileType">
          <ykc-radio
            :data="[
              { id: 1, name: '直流' },
              { id: 2, name: '交流' },
            ]"
            v-model="ruleForm.pileType"></ykc-radio>
        </ykc-form-item>

        <ykc-form-item label="充电桩功率" prop="pilePower">
          <ykc-input
            placeholder="请输入充电桩功率"
            type="Number"
            maxlength="30"
            v-model="ruleForm.pilePower">
            <template slot="append">KW</template>
          </ykc-input>
        </ykc-form-item>

        <ykc-form-item label="辅助电源" prop="pileBoostVoltage">
          <ykc-dropdown
            :data="[
              { id: 1, name: '12V' },
              { id: 2, name: '24V' },
              { id: 3, name: '12&24V' },
            ]"
            v-model="ruleForm.pileBoostVoltage"></ykc-dropdown>
        </ykc-form-item>

        <ykc-form-item label="充电终端数量" prop="pileGunNum">
          <ykc-input
            placeholder="请输入充电终端数量"
            maxlength="30"
            :disabled="isEdit"
            v-model="ruleForm.pileGunNum"></ykc-input>
        </ykc-form-item>

        <ykc-form-item label="电桩国标" prop="nationalStandard">
          <ykc-radio
            :data="[
              { id: 1, name: '国标2011' },
              { id: 2, name: '国标2015' },
            ]"
            v-model="ruleForm.nationalStandard"></ykc-radio>
        </ykc-form-item>

        <ykc-form-item label="充电模块品牌" prop="moduleName">
          <ykc-input
            placeholder="请输入充电模块品牌"
            maxlength="30"
            v-model="ruleForm.moduleName"></ykc-input>
        </ykc-form-item>

        <ykc-form-item label="充电枪线品牌" prop="gunLineName">
          <ykc-input
            placeholder="请输入充电枪线品牌"
            maxlength="30"
            v-model="ruleForm.gunLineName"></ykc-input>
        </ykc-form-item>

        <ykc-form-item label="额定电压" prop="voltage">
          <ykc-input
            placeholder="请输入额定电压"
            type="Number"
            maxlength="30"
            v-model="ruleForm.voltage"></ykc-input>
        </ykc-form-item>

        <ykc-form-item label="额定电流" prop="current">
          <ykc-input
            placeholder="请输入额定电流"
            type="Number"
            maxlength="30"
            v-model="ruleForm.current"></ykc-input>
        </ykc-form-item>
      </ykc-form>
    </div>
  </ykc-form>
</template>
<script>
  import regexpObj from '@/utils/regexp';
  import { brandModelPage } from '@/service/apis';

  export default {
    props: {
      id: {
        type: Number,
      },
      isEdit: {
        type: Boolean,
        default: false,
      },
    },
    name: 'AddOrEdit',
    data() {
      return {
        detail: {},
        brandList: [], // 品牌列表
        ruleForm: {
          modelName: '',
          isName: 1,
          name: '',
          inputName: '',
          pileType: 1,
          pilePower: null,
          pileBoostVoltage: null,
          pileGunNum: null,
          nationalStandard: 1,
          moduleName: '',
          gunLineName: '',
          voltage: null,
          current: null,
        },
        rules: {
          // modelName: [
          //   { required: true, message: '请输入电桩型号', trigger: 'blur' },
          //   {
          //     trigger: 'blur',
          //     pattern: regexpObj.regexp.input.accountType.regexp,
          //     message: regexpObj.regexp.input.accountType.errorTips.error('电桩型号'),
          //   },
          // ],
          isName: [{ required: true, message: '请选择电桩品牌', trigger: 'blur' }],
          name: [{ required: true, message: '请选择品牌', trigger: 'blur' }],
          inputName: [
            { required: true, message: '请输入新品牌', trigger: 'blur' },
            {
              trigger: 'blur',
              pattern: regexpObj.regexp.input.nameType.regexp,
              message: regexpObj.regexp.input.nameType.errorTips.error('新品牌'),
            },
          ],
          pileType: [{ required: true, message: '请选择电桩类型', trigger: 'blur' }],
          pilePower: [
            { required: true, message: '请输入充电桩功率', trigger: 'blur' },
            // {
            //   trigger: 'blur',
            //   pattern: regexpObj.regexp.input.integerNumberType.regexp,
            //   message: regexpObj.regexp.input.integerNumberType.errorTips.error(
            //     '充电桩功率',
            //     99999999
            //   ),
            // },
          ],
          pileBoostVoltage: [{ required: true, message: '请选择辅助电源', trigger: 'blur' }],
          pileGunNum: [
            { required: true, message: '请输入充电终端数量', trigger: 'blur' },
            {
              trigger: 'blur',
              pattern: regexpObj.regexp.input.integerNumberType.regexp,
              message: regexpObj.regexp.input.integerNumberType.errorTips.error(
                '充电终端数量',
                99999999
              ),
            },
          ],
          nationalStandard: [{ required: true, message: '请选择电桩国标', trigger: 'blur' }],
          moduleName: [
            { required: true, message: '请输入模块品牌', trigger: 'blur' },
            {
              trigger: 'blur',
              pattern: regexpObj.regexp.input.nameType.regexp,
              message: regexpObj.regexp.input.nameType.errorTips.error('模块品牌'),
            },
          ],
          gunLineName: [
            { required: true, message: '请输入枪线品牌', trigger: 'blur' },
            {
              trigger: 'blur',
              pattern: regexpObj.regexp.input.nameType.regexp,
              message: regexpObj.regexp.input.nameType.errorTips.error('枪线品牌'),
            },
          ],
          voltage: [
            { required: true, message: '请输入额定电压', trigger: 'blur' },
            {
              trigger: 'blur',
              pattern: regexpObj.regexp.input.integerNumberType.regexp,
              message: regexpObj.regexp.input.integerNumberType.errorTips.error(
                '额定电压',
                99999999
              ),
            },
          ],
          current: [
            { required: true, message: '请输入额定电流', trigger: 'blur' },
            {
              trigger: 'blur',
              pattern: regexpObj.regexp.input.integerNumberType.regexp,
              message: regexpObj.regexp.input.integerNumberType.errorTips.error(
                '额定电流',
                99999999
              ),
            },
          ],
        },
      };
    },
    created() {
      // 获取品牌列表
      this.brandListFun();

      // 编辑回显
      if (this.id) {
        brandModelPage
          .detail({ id: this.id })
          .then(res => {
            this.ruleForm = {
              ...this.ruleForm,
              ...res,
            };
          })
          .catch(() => {});
      }
    },
    computed: {
      formData() {
        const formData = {
          modelName: this.ruleForm.modelName,
          name: this.ruleForm.isName === 1 ? this.ruleForm.name : this.ruleForm.inputName,
          pileType: this.ruleForm.pileType,
          pilePower: Number(this.ruleForm.pilePower),
          pileBoostVoltage: Number(this.ruleForm.pileBoostVoltage),
          pileGunNum: Number(this.ruleForm.pileGunNum),
          nationalStandard: this.ruleForm.nationalStandard,
          moduleName: this.ruleForm.moduleName,
          gunLineName: this.ruleForm.gunLineName,
          voltage: Number(this.ruleForm.voltage),
          current: Number(this.ruleForm.current),
        };

        if (this.id) {
          formData.id = this.id;
        }

        return formData;
      },
    },
    methods: {
      /**
       * @desc 获取品牌列表列表
       * */
      brandListFun() {
        brandModelPage
          .brandList({ keyword: '' })
          .then(res => {
            const params = res;
            params.map(item => {
              return this.brandList.push({
                id: item,
                name: item,
              });
            });
          })
          .catch(err => {
            console.log(err);
          });
      },
      /**
       * 表单提交事件，用于父组件定义的"完成"或"保存"按钮点击事件
       */
      submitForm() {
        return new Promise(resolve => {
          this.validateForm().then(() => {
            if (!this.id) {
              brandModelPage
                .saveOrUpdate(this.formData)
                .then(res => {
                  console.log(res);
                  this.$message({
                    message: '保存成功',
                    type: 'success',
                  });
                  resolve();
                })
                .catch(() => {});
            } else {
              console.log('编辑', this.formData);
              brandModelPage
                .saveOrUpdate(this.formData)
                .then(res => {
                  console.log(res);
                  this.$message({
                    message: '保存成功',
                    type: 'success',
                  });
                  resolve();
                })
                .catch(() => {});
            }
          });
        });
      },
      validateForm() {
        return new Promise((resolve, reject) => {
          this.$refs.ruleForm.validate(valid => {
            if (valid) {
              resolve();
            } else {
              reject();
            }
          });
        });
      },
    },
  };
</script>
