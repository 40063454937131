<!--
 * @Author: wang peng
 * @Description: 品牌型号列表
-->
<template>
  <scroll-layout class="fleet-match-list">
    <template slot="header">
      <ykc-search
        ref="YkcSearch"
        :data="searchData"
        :searchForm="searchParams"
        @click="handleSearchConfirm"
        @clean="handleSearchClean"></ykc-search>
    </template>
    <div>
      <ykc-table
        ref="YkcTable"
        operateFixedType="right"
        :data="tableData"
        :title="tableTitle"
        :columns="tableColumns"
        :operateButtons="tableOperateButtons.filter(item => item.enabled())">
        <template #headerButtons>
          <div class="table-header-button">
            <ykc-button v-rbac="'asset:station:brand:add'" type="plain" @click="add">
              新增
            </ykc-button>
            <ykc-button v-rbac="'asset:station:brand:export'" type="plain" @click="doExportFile">
              导出
            </ykc-button>
          </div>
        </template>
        <ykc-pagination
          align="right"
          slot="pagination"
          ref="YkcPagination"
          :total="pageInfo.total"
          :page-size.sync="pageInfo.size"
          :current-page.sync="pageInfo.current"
          @size-change="handlePaginationSizeChange"
          @current-change="handlePaginationCurrentChange"></ykc-pagination>
      </ykc-table>
      <ykc-drawer
        :show.sync="showDrawer"
        :title="drawerTitle"
        :on-close="onAddDrawerClose"
        :before-cancel="onAddDrawerCancel">
        <template #footer>
          <div class="drawer-button-wrap">
            <template v-for="(btn, i) in drawerButtons">
              <ykc-button :type="btn.type" v-if="btn.enabled()" :key="i" @click="btn.handler">
                {{ btn.label }}
              </ykc-button>
            </template>
          </div>
        </template>
        <div class="drawer-main-body" v-if="showDrawer">
          <AddOrEdit
            :id="id"
            :isEdit="isEdit"
            ref="addOrEdit"
            v-if="drawerType === 'edit'"></AddOrEdit>
        </div>
      </ykc-drawer>
    </div>
  </scroll-layout>
</template>
<script>
  import YkcDialog from '@/components/ykc-ui/dialog';
  import { brandModelPage } from '@/service/apis';
  import { offlineExport, code } from '@/utils';
  import AddOrEdit from './AddOrEdit.vue';

  export default {
    name: 'brandAndModelList',
    components: {
      AddOrEdit,
    },
    data() {
      return {
        id: '',
        isEdit: false,
        showDrawer: false,
        drawerType: 'edit',
        dictionary: {},
        brandListData: [], // 品牌列表
        searchParams: {
          modelName: '',
          name: '',
          pilePowerMax: '',
          pilePowerMin: '',
          pileGunNumMax: '',
          pileGunNumMin: '',
          moduleName: '',
          pileType: '',
          pileBoostVoltage: '',
          nationalStandard: '',
        },
        // 列表表格
        tableTitle: '型号列表',
        tableData: [],
        tableOperateButtons: [], // 列表操作
        tableColumns: [
          { label: '型号编码', prop: 'id', minWidth: '100px' },
          {
            label: '型号',
            prop: 'modelName',
            minWidth: '200px',
            // scopedSlots: {
            //   default: ({ row }) => {
            //     return (
            //       <el-tooltip effect="dark" content={row.modelName} placement="top-start">
            //         <span>{row.modelName}</span>
            //       </el-tooltip>
            //     );
            //   },
            // },
          },
          {
            label: '品牌',
            prop: 'name',
            minWidth: '200px',
            // scopedSlots: {
            //   default: ({ row }) => {
            //     return (
            //       <el-tooltip effect="dark" content={row.name} placement="top-start">
            //         <span>{row.name}</span>
            //       </el-tooltip>
            //     );
            //   },
            // },
          },
          { label: '功率(kw)', prop: 'pilePower', minWidth: '100px' },
          {
            label: '类型',
            prop: 'pileType',
            minWidth: '100px',
            scopedSlots: {
              default: ({ row }) => {
                const title = row.pileType === 1 ? '直流' : '交流';
                return <span title={title}>{title}</span>;
              },
            },
          },
          { label: '枪数量', prop: 'pileGunNum', minWidth: '100px' },
          {
            label: '辅助电源',
            prop: 'pileBoostVoltage',
            minWidth: '100px',
            scopedSlots: {
              default: ({ row }) => {
                return (
                  <span title={this.getPileBoostVoltage(row.pileBoostVoltage)}>
                    {this.getPileBoostVoltage(row.pileBoostVoltage)}
                  </span>
                );
              },
            },
          },
          {
            label: '电桩国标',
            prop: 'nationalStandard',
            minWidth: '100px',
            scopedSlots: {
              default: ({ row }) => {
                const title = row.nationalStandard === 1 ? '国标2011' : '国标2015';
                return <span title={title}>{title}</span>;
              },
            },
          },
          {
            label: '模块品牌',
            prop: 'moduleName',
            minWidth: '200px',
            // scopedSlots: {
            //   default: ({ row }) => {
            //     return (
            //       <el-tooltip effect="dark" content={row.moduleName} placement="top-start">
            //         <span>{row.moduleName}</span>
            //       </el-tooltip>
            //     );
            //   },
            // },
          },
          {
            label: '枪线品牌',
            prop: 'gunLineName',
            minWidth: '200px',
            // scopedSlots: {
            //   default: ({ row }) => {
            //     return (
            //       <el-tooltip effect="dark" content={row.gunLineName} placement="top-start">
            //         <span>{row.gunLineName}</span>
            //       </el-tooltip>
            //     );
            //   },
            // },
          },
        ],
        pageInfo: {
          total: 0,
          current: 1,
          size: 10,
        },
        drawerButtons: [
          {
            label: '取消',
            type: 'plain',
            enabled: () => {
              return true;
            },
            handler: () => {
              YkcDialog({
                dialogType: 'feedback',
                showTitle: false,
                showFooter: true,
                desc: '取消后操作将不被保存，确认是否取消？',
                onCancel: dialogDone => {
                  dialogDone(); // 隐藏弹窗
                },
                onConfirm: dialogDone => {
                  dialogDone(); // 隐藏弹窗
                  this.showDrawer = false; // 隐藏抽屉
                },
              });
            },
          },
          {
            label: '保存',
            enabled: () => {
              return true;
            },
            handler: () => {
              this.$refs.addOrEdit.submitForm().then(() => {
                this.showDrawer = false;
                this.requestBrandList();
                this.requestList();
              });
            },
          },
        ],
      };
    },
    computed: {
      searchData() {
        const all = { id: '', name: '全部' };
        return [
          {
            comName: 'YkcInput',
            key: 'modelName',
            label: '型号名称',
            placeholder: '请输入型号名称',
          },
          {
            comName: 'YkcInput',
            key: 'name',
            label: '品牌名称',
            placeholder: '请输入品牌名称',
          },
          {
            isDouble: true,
            appendText: 'KW',
            comName: 'YkcInput',
            label: '电桩功率',
            key1: 'pilePowerMin',
            key2: 'pilePowerMax',
            placeholder: '请输入电桩功率',
          },
          {
            isDouble: true,
            comName: 'YkcInput',
            label: '枪数量',
            key1: 'pileGunNumMin',
            key2: 'pileGunNumMax',
            placeholder: '请输入枪数量',
          },
          {
            remote: true,
            clearable: true,
            comName: 'YkcDropdown',
            key: 'moduleName',
            label: '模块品牌',
            placeholder: '请输入模块品牌',
            data: this.brandListData,
          },
          {
            comName: 'YkcDropdown',
            key: 'pileType',
            label: '电桩类型',
            placeholder: '请选择电桩类型',
            data: [
              all,
              ...[
                { id: '1', name: '直流' },
                { id: '2', name: '交流' },
              ],
            ],
          },
          {
            comName: 'YkcDropdown',
            key: 'pileBoostVoltage',
            label: '辅助电源',
            placeholder: '请选择辅助电源',
            data: [
              all,
              ...[
                { id: 1, name: '12V' },
                { id: 2, name: '24V' },
                { id: 3, name: '12V&24V' },
              ],
            ],
          },
          {
            comName: 'YkcDropdown',
            key: 'nationalStandard',
            label: '电桩国标',
            placeholder: '请选择电桩国标',
            data: [
              all,
              ...[
                { id: '1', name: '国标2011' },
                { id: '2', name: '国标2015' },
              ],
            ],
          },
        ];
      },
      drawerTitle() {
        if (this.drawerType === 'edit') {
          return `型号${this.isEdit ? '编辑' : '新增'}`;
        }
        return ``;
      },
    },
    created() {
      this.requestBrandList();
      this.requestList();
      this.initData();
    },
    methods: {
      getPileBoostVoltage(data) {
        let str = '';
        if (data === 1) {
          str = '12V';
        } else if (data === 2) {
          str = '24V';
        } else if (data === 3) {
          str = '12V&24V';
        }
        return str;
      },
      /**
       * 初始数据
       */
      initData() {
        this.tableOperateButtons = [
          {
            enabled: () => code('asset:station:brand:edit'),
            render: (h, data, { row }) => (
              <ykc-button
                type="text"
                onClick={() => {
                  this.isEdit = true;
                  this.id = row.id;
                  this.drawerType = 'edit';
                  this.showDrawer = true;
                }}>
                编辑
              </ykc-button>
            ),
          },
        ];
      },
      /**
       * 请求模块品牌列表
       */
      requestBrandList() {
        brandModelPage
          .moduleBrandList({ keyword: '' })
          .then(res => {
            this.brandListData = (res || []).map(item => {
              return {
                id: item,
                name: item,
              };
            });
          })
          .catch(err => {
            console.log(err);
          });
      },
      /**
       * 品牌型号分页查询
       */
      requestList() {
        const reqParams = {
          ...this.searchParams,
          ...this.pageInfo,
        };
        brandModelPage
          .queryForPage(reqParams)
          .then(res => {
            console.log('品牌型号分页查询+++++', res);
            this.tableData = res.records || [];
            this.pageInfo.total = res.total || 0;
          })
          .catch(err => {
            console.log(err);
          });
      },
      /**
       * 新增
       */
      add() {
        this.isEdit = false;
        this.id = '';
        this.drawerType = 'edit';
        this.showDrawer = true;
      },
      /**
       * 导出
       */
      doExportFile() {
        offlineExport(
          {
            bizType: 'brand_model',
            downloadType: 'brand_model',
            jsonNode: {
              ...this.searchParams,
            },
          },
          this.pageInfo.total
        );
      },
      /**
       * @desc 单个停用
       * */
      doDisableActivity(row) {
        YkcDialog({
          showTitle: false,
          showFooter: true,
          dialogType: 'feedback',
          desc: '点击确认将停用该方案',
          onCancel: done => {
            console.log(done);
            done();
          },
          onConfirm: done => {
            console.log(done);
            brandModelPage
              .stop({ id: row.id || '' })
              .then(response => {
                console.log(response);
                this.$message.success('方案停用成功');
                this.requestList();
              })
              .catch(err => {
                console.log(err);
              });
            done();
          },
        });
      },
      /**
       * 点击筛选查询按钮
       * */
      handleSearchConfirm(searchData) {
        console.log('searchData++++++', searchData);
        this.pageInfo.current = 1;
        Object.assign(this.searchParams, searchData);

        this.requestList();
      },
      /**
       * 点击清空查询按钮
       * */
      handleSearchClean(form) {
        Object.assign(this.searchParams, form);
        console.log('清空查询', form);
      },
      /**
       *
       * */
      handlePaginationCurrentChange(current) {
        this.pageInfo.current = current;
        this.requestList();
      },
      /**
       *
       * */
      handlePaginationSizeChange(size) {
        this.pageInfo.current = 1;
        this.pageInfo.size = size;
        this.requestList();
      },
      /**
       * 取消操作
       */
      beforeCancel(drawerDone) {
        this.$dialog({
          dialogType: 'feedback',
          showTitle: false,
          closeOnClickModal: false,
          desc: '取消后操作将不被保存，确认是否取消？',
          onConfirm: done => {
            // 关闭弹框
            done();
            // 关闭抽屉
            drawerDone();
          },
        });
      },
      /**
       *
       * */
      onAddDrawerClose(done) {
        done();
      },
      /**
       * @desc 点击抽屉取消按钮 callback
       * */
      onAddDrawerCancel(drawerDone) {
        YkcDialog({
          dialogType: 'feedback',
          showTitle: false,
          showFooter: true,
          desc: '取消后操作将不被保存，确认是否取消？',
          onCancel: dialogDone => {
            dialogDone();
          },
          onConfirm: dialogDone => {
            dialogDone();
            drawerDone();
            if (this.isEdit) {
              this.isEdit = false;
            }
          },
        });
      },
    },
  };
</script>
<style lang="scss" scoped></style>
